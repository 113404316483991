<template>
    <v-container>
        <recognitionsListManage />
    </v-container>
</template>

<script>

import recognitionsListManage from "@/components/admin/recognitions/recognitionsListManage";
export default {
    name: 'Recognitions',
    components: { recognitionsListManage }
}
</script>

<style scoped>

</style>
