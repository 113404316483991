<template>
    <v-container
        fluid
        class="pt-0 mx-0 px-0"
    >
        <!--      Add purpose-->
        <div
            class="my-0 mx-0 px-0"
            style="position: relative"
        >
            <div
                class="title-post mr-16"
            >
                <span class="my-0 py-0">
                    {{ $t('admin.recognitions.title') }}
                </span>
            </div>

            <!--            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-fab-transition>
                        <v-btn
                            v-show="true"
                            color="secondary mt-2"
                            dark
                            absolute
                            top
                            right
                            fab
                            style="right: 0px;"
                            v-bind="attrs"
                            @click="createPurpose"
                            v-on="on"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>

                <span>{{ $t('recognitions.newRecognitionToolTip') }}</span>
            </v-tooltip>-->

            <v-row>
                <v-col class="px-3">
                    <!--            <v-btn
                class="mx-2 my-2"
                fab
                dark
                color="secondary"
                @click="createPurpose"
            >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>-->

                    <selectFilters @update:filter="filtersUsers = $event">
                        <selectRecognitionType @update:filter="filterTypes = $event" />
                    </selectFilters>

                    <!--      Purposes List-->
                    <!--      :search="search"-->
                    <v-data-iterator
                        :items="dataFilter"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        hide-default-footer
                        no-data-text=""
                    >
                        <template v-slot:header>
                            <v-toolbar
                                dark
                                color="primary"
                                class="mb-1 mt-3 rounded-lg"
                            >
                                <v-text-field
                                    v-model="search"
                                    clearable
                                    flat
                                    solo-inverted
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    :label="$t('common.search')"
                                />

                                <template>
                                    <v-spacer />
                                    <!--                                    <v-select
                                        v-model="sortBy"
                                        flat
                                        solo-inverted
                                        hide-details
                                        :items="keys"
                                        prepend-inner-icon="mdi-magnify"
                                        label="Sort by"
                                    />-->
                                    <v-spacer />
                                    <v-btn-toggle
                                        v-model="sortDesc"
                                        mandatory
                                        class="ml-2"
                                    >
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            :value="false"
                                        >
                                            <v-icon>mdi-arrow-up</v-icon>
                                        </v-btn>
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            :value="true"
                                        >
                                            <v-icon>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </template>
                            </v-toolbar>
                            {{ $t('admin.recognitions.showingCount', {count: dataFilter.length}) }}
                        </template>

                        <template v-slot:default="props">
                            <v-row>
                                <v-col
                                    v-for="item in props.items"
                                    :key="item.id"
                                    cols="12"
                                >
                                    <recognitionSingle
                                        :item="item"
                                        :show-owner="true"
                                        :show-likes="true"
                                        @update:recognitions="fetchPurpose"
                                    />
                                </v-col>
                            </v-row>
                        </template>

                        <template
                            v-if="items && items.length"
                            v-slot:footer
                        >
                            <v-row
                                class="mt-2 ml-0 mr-0"
                                align="center"
                                justify="center"
                            >
                                <span class="grey--text">{{ $t('common.itemsPerPage') }}</span>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dark
                                            text
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ itemsPerPage }}
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(number, index) in itemsPerPageArray"
                                            :key="index"
                                            @click="updateItemsPerPage(number)"
                                        >
                                            <v-list-item-title>{{ number }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-spacer />

                                <span
                                    class="grey--text"
                                >
                                    {{ $t('common.page') + " " + page + " " + $t('common.of') + " " + numberOfPages }}
                                </span>
                                <div>
                                    <v-btn
                                        fab
                                        dark
                                        color="primary"
                                        class="ml-4 mr-1"
                                        @click="formerPage"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        color="primary"
                                        class="ml-1 mr-4"
                                        @click="nextPage"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-col>
            </v-row>
            <confirm-dialog
                ref="confirm"
            />
        </div>
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import recognitionSingle from '@/components/recognitions/recognitionSingle'
import selectFilters from "@/components/recognitions/selectFilters";
import { mapGetters } from 'vuex'
import selectRecognitionType from "@/components/recognitions/selectRecognitionType";

export default {
    name: 'RecognitionsListManage',
    components: { ConfirmDialog, recognitionSingle, selectFilters, selectRecognitionType },
    data() {
        return {
            urlMain: '/purposes?include=owner,recognized_user,likes',
            items: [], // purposes
            itemSelected: null,
            itemsPerPageArray: [5, 10, 50, 100],
            search: '',
            filter: {},
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            sortBy: 'relationships.likes.meta.total',
            purposeCloud: null,
            overlayVideo: false,
            filtersUsers: null,
            filterTypes: null
        }
    },
    computed: {
        ...mapGetters("languages", ['getLang']),
        ...mapGetters(['getMyGroups']),
        getItemsWithFiltersTypesRecognition() {
            const items = this.items;
            const filter = this.filterTypes;
            if (filter) {
                return items.filter(recognition => {
                    return filter.includes(recognition.attributes.type_recognition);
                });
            } else {
                return items;
            }
        },
        getItemsWithFiltersUsers() {
            const items = this.getItemsWithFiltersTypesRecognition;
            const filter = this.filtersUsers;
            if (filter) {
                return items.filter(child => {
                    // const userDataFilter = (child?.userData?.attributes?.name ?? "") + " " + (child?.userData?.attributes?.lastname ?? "");
                    const u = child?.userData; // child?.ownerData

                    return (u &&
                        filter.pais.includes(u.attributes.pais ?? '') &&
                        filter.centro.includes(u.attributes.centro ?? '') &&
                        filter.sociedad.includes(u.attributes.sociedad ?? '') &&
                        filter.uo1.includes(u.attributes.uo1 ?? ''));
                });
            } else {
                return items;
            }
        },
        dataFilter() {
            let search = this.search;

            const items = this.getItemsWithFiltersUsers;
            if (!search) return items;

            search = search.toUpperCase();

            return items.filter(child => {
                const userDataFilter = (child?.userData?.attributes?.name ?? "") + " " + (child?.userData?.attributes?.lastname ?? "");
                // const ownerDataFilter = (child?.ownerData?.attributes?.name ?? "") + " " + (child?.ownerData?.attributes?.lastname ?? "");

                const att = child.attributes;

                const bodyText = this.generalHelper.stripTags(att.body ?? '').toUpperCase();

                return (bodyText.includes(search)) ||
                    userDataFilter.toUpperCase().includes(search) ||
                    /* ownerDataFilter.toUpperCase().includes(search) || */
                    (att['created-at'] && this.dateHelper.getDateTimeTableFormat(att['created-at']).includes(search)) ||
                    (att['updated-at'] && this.dateHelper.getDateTimeTableFormat(att['updated-at']).includes(search));
            }
            );
        },
        numberOfPages() {
            return Math.ceil(this.dataFilter.length / this.itemsPerPage)
        }
        /* filteredKeys() {
            return this.keys.filter(key => key !== 'Name')
        } */
    },
    async mounted() {
        this.fetchPurpose();

        this.overlayVideo = !await this.$store.dispatch('cache/getAndUpdatePersistentInfo', { key: 'recognition-create-video', value: true });
    },
    methods: {

        async fetchPurpose() {
            try {
                this.$store.dispatch('loading/start');

                const purposes = await axios
                    .get(this.urlMain);

                this.initialize(purposes.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            } finally {
                this.$store.dispatch('loading/end')
            }
        },
        initialize($data) {
            // console.log(items)
            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0

            const recognitions = $data.data;
            const users = $data.included;

            this.items = recognitions.map(recognition => {
                recognition.userData = users.find(user => user.id == recognition.attributes.recognized_user_id);
                recognition.ownerData = users.find(user => user.id == recognition.attributes["user-id"]);
                return recognition;
            });

            // console.log('this.items ', this.items)
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },

        createPurpose() {
            this.$router.push({ name: 'RecognitionCreate' });
        }
    }
}
</script>

<!--<style >
.ql-align-right{
  text-align: right !important;
}
</style>-->
